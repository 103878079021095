import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CabinsFullWidth from "../components/cabinsFullWidth"
import GalleryBlock from "../elements/galleryBlock"

const Gallery = () => {  

  return (
    <Layout>
      <SEO title="Gallery" />
      <GalleryBlock />
      <CabinsFullWidth />
    </Layout>
  )
}

export default Gallery