import React from 'react'
import styled from "styled-components"
import {useStaticQuery, graphql} from 'gatsby';
import Img from "gatsby-image"
import backgroundImageSand from "../assets/images/sand_texture-bg.jpg"
import PageHeader from "../elements/pageHeader"

const GalleryBlock = () => {
  const data = useStaticQuery(graphql`
    query GalleryQuery {
      allGalleryJson {
        edges {
          node {
            alt
            img {
              childImageSharp {
                fluid (maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  function getGalleryImages(data) {
    const galleryArray = [];
    data.allGalleryJson.edges.forEach((item, index) => {
      galleryArray.push(
        <ImageCard key={index}>
          <ImageWrapper
             fluid={item.node.img.childImageSharp.fluid} 
          />
        </ImageCard>
      )
    })
    return galleryArray;
  }

  return (
    <>
    <PageHeader header="Gallery" />
      <GalleryContainer>
        <GalleryWrapper>{getGalleryImages(data)}</GalleryWrapper>
      </GalleryContainer>
    </>
  )
}

export default GalleryBlock

const GalleryContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #fff;
  color: #fff;
  background-image: url(${backgroundImageSand});
  padding-top: 0;
`


const GalleryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-items: center;
  padding: 0 2rem;
  
  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
  }
`

const ImageCard = styled.div`
  line-height: 1;
  width: 100%;
  height: 400px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  vertical-align: top;  
  box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
`

const ImageWrapper = styled(Img)`
  height: 100%;
  max-width: 100%;
  position: relative;
  border-radius: 5px;
`








